import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import ResetButton from "assets/resetbutton.jpg";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBanner from "components/commontools/toolsBanner";
import ToolInput from "components/commontools/InsertTool";
import ToolOutput from "components/commontools/ToolOutput";
import useDebounce from "components/QualityTools/useDebounce";
import { Languages } from "components/QualityTools/LanguagesData";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import { Link, navigate } from "gatsby";
import { useLocation } from "@reach/router";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import { Tooltip } from "react-tooltip";
import CleanButton from "assets/cleanBtn.svg";
import ToolFaq from "components/QualityTools/ToolFaq";
import { ConsistencyToolFaqData } from "components/FAQ/config";

const GlossaryGenerator = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const refDiv = useRef(null);
  const debouncedInputText = useDebounce(inputText, 2000);
  const debouncedOutputText = useDebounce(outputText, 2000);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);
  const [shareId, setShareId] = useState("");
  const [copySuccess, setCopySuccess] = useState("Copy");
  



  useEffect(() => {
    if (refDiv.current) {
      setDivHeight(refDiv.current.offsetHeight);
    }
  }, [refDiv,divHeight]);

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectSourceLanguage(debouncedInputText);
    }
  }, [debouncedInputText]);
  useEffect(() => {
    if (debouncedOutputText?.trim()?.length >= 3) {
      detectTargetLanguage(debouncedOutputText);
    }
  }, [debouncedOutputText]);

  const detectSourceLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const language = response.data?.data?.language_probability?.code;
      setTranslateFromInput(language);
      const languageName = Languages?.find((lang) => lang?.code === language);

      if (languageName?.name) {
        setInputLang(language);
      }

    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };
  const detectTargetLanguage = async (text) => {
    try {
      const response = await axios.post(
        "https://api.machinetranslation.com/v1/detect/language",
        {
          text: text.slice(0, 50),
        }
      );
      const languageTo = response.data?.data?.language_probability?.code;
      setTranslateToInput(languageTo);
      const languageToName = Languages.find(
        (lang) => lang?.code === languageTo
      );

      if (languageToName?.name) {
        setOutputLang(languageTo);
      }

    } catch (error) {
      console.error("Error detecting language:", error);
    }
  };

  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError("");
    setShowOutput(false);
    try {
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "consistencyChecker",
        source_text: inputText,
        translation: outputText,
        source_language_code: transtalteFromInput,
        target_language_code: transtalteToInput,
      });

      // setAnalysisResult(response);
      const newShareId = response?.meta?.share_id;

      setShareId(newShareId);

      setShowOutput(true);
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(`/tools/translation-consistency-checker/result?share-id=${newShareId}`)

    } catch (error) {
      // console.error("Error during quality analysis:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  const isButtonDisabled =
    !transtalteFromInput || !transtalteToInput || !inputText || !outputText;
 useEffect(() => {
    if (refDiv?.current) {
      setDivHeight(refDiv?.current?.base?.offsetHeight);
    }
  }, [refDiv, analysisResult]);
  const handleSourceClearText = () => {
  setInputText("");
   
    setInputLang("");
  
    setWordCountInput(0);

  };

  const handleTargetClearText = () => {


    setOutputText("");
  
    setOutputLang("");
 

    setWordCountOutput(0);
   
  };



  return (
    <Layout>
      <SEO
        title="Translation Consistency Checker"
        description="Maintain consistent terminology in your translations with the consistency checker. Get a detailed report and consistency score to ensure accuracy.​"
        slug="/tools/translation-consistency-checker"
      />
      <div className="bg-[#F5F5F5] ">
        <div className=" w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-12 lg:py-[60px]">
          <ToolsBanner
            text="Translation Consistency Checker"
            title="Automatically Identify and Fix Terminology Inconsistencies"
            description="Paste the source text and its translation, and the tool will check consistency of terminology. The report will highlight any inconsistent terms or phrases, along with an overall consistency score. "
          />
          <div className="lg:flex gap-3 mt-12">
            <div className="flex flex-col gap-3 w-full lg:w-[70%] ">
              <div className="h-full lg:flex items-center  bg-white rounded-lg shadow-lg ">
                <ToolInput
                  lang={inputLang}
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
            
                  setInputLang={setInputLang}
                  setWordCount={setWordCountInput}
                  iscleanBtn={true}
                  refDiv={refDiv}
                  result={analysisResult}
                  divHeight={divHeight}
                  // handleClearText={handleClearText}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  isEnterText={true}
                >
                  {" "}
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                          Source text
                        </p> 
                        {inputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={handleSourceClearText}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                </ToolInput>

                <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

                <ToolInput
                  lang={outputLang}
                  text={outputText}
                  wordCount={wordCountOutput}
                  setText={setOutputText}
                  setWordCount={setWordCountOutput}
                  refDiv={refDiv}
                  result={analysisResult}
                  divHeight={divHeight}
                  placeholder="Enter your text here"
                  // handleClearText={handleClearText}
                  isRefreshBtn={true}
                  isTranslation ={true}
                  isEnterText={true}
                >
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                        <p className=" text-[#6E6E6E] font-opensans font-bold">
                        Translated text
                        </p> 
                        {outputText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={handleTargetClearText}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                      </div>
                </ToolInput>
              </div>
            </div>

            <ToolOutput
              handleClick={handleClick}

              isButtonDisabled={isButtonDisabled}
            //   ResetButton={ResetButton}
              loading={loading}
            //   handleReset={handleReset}

              error={error}
              ref={refDiv}
              isRefreshBtn={true}
              buttontext="Check Consistency"
              text="Your results will appear here. <br/> Please enter your texts and click “Check Consistency” to begin."
            />
           
              
       
          </div>
          <p className="w-full text-center text-base mt-12 mb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
        
          <p className="mt-8 md:mt-12 text-lg text-center font-opensans leading-[26px]">
            Paste the source text and its translation, and the tool will check
            consistency of terminology. The report will highlight any
            inconsistent terms or phrases, along with an overall consistency
            score.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={ConsistencyToolFaqData} />
    </Layout>
  );
};

export default GlossaryGenerator;
